body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.red {
  background-color: bisque;
}

.hero_img {
  background-image: url(./assets/woman_glasses/woman_glasses.jpg);
  background-attachment: "fixed";
  background-position: "center";
  background-repeat: "no-repeat";
  background-size: "cover";
}

.half_width_border {
  width: 300px;
  border-top: 1px solid #2a3b8f;
}

.error-text {
  color: red;
}

.controlled-input-wrapper {
  margin-bottom: 20px;
}

.controlled-input-label {
  font-size: 14px;
  font-weight: 600;
  color: #4F4F4F;
  margin-bottom: 25px !important;
  font-family: inherit !important;
}

.form-text-input input:focus {
  outline: none !important;
}