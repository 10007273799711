.auth-sidebar {
  background: linear-gradient(rgba(12, 12, 12, 0.397), rgba(12, 12, 12, 0.479)),
    url(../../../images/bg/jas_hero_2.jpg);
  height: 100vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.mt-50 {
  margin-top: 50%;
}
.gap-10 {
  gap: 10rem !important;
}

.progress-nav {
  left: 5.3rem;
  position: absolute;
  top: 13.8rem;
  width: 12rem !important;
  margin-top: 5rem !important;
  z-index: -1;
  height: 0.3rem !important;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
