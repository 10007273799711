.read-more-btn {
    background-color: rgb(21, 118, 236);
    font-size: 14px;
    border-radius: 20px;
    border: 1px solid rgb(21, 118, 236);
    color: white;
    padding: 0.7rem;
    width: 9rem;
    text-align: center;
    margin-top: 2rem;
}

.read-more-btn:hover {
    background-color: white;
    color: rgb(21, 118, 236);
    cursor: pointer;
}

.pdf-button-custom {
    display: flex !important;
    width: 100%;
    justify-content: center !important;
}

.resend-text {
    font-size: 16px !important;
    color: #747474;
    margin-top: 2rem;

}

.form-text-input::placeholder {
    color: #bfbfbf !important;
    font-size: 14px !important;

}

.sms-input::placeholder {
    color: #E1E4E8 !important;
    font-size: 14px !important;
}

.PhoneInput input::placeholder {
    font-size: 14px !important;
    color: #E1E4E8 !important;
    /* color:red!important; */
}

.resend-header {
    font-size: 24px !important;
    color: #4F4F4F;

}

.login-subtext {
    font-size: 16px;
    color: #747474;
    margin-top: 2rem;

}


.grant-base-accordion .Mui-expanded {
    min-height: 20px !important;
}


@media (max-width: 990px) {
    .auth-left {
        display: none;
    }

    .auth-right {
        width: 100% !important;
    }

    .html2pdf__page-break {
        margin-top: 30px !important;
    }

    /* … */
}